// Core packages
import React from "react";

// Third party packages
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  useAccordionButton,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// Custom packages
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import header from "../assets/header/info-utili.jpg";
import logo_1 from "../assets/icone/+.png";
import logo_2 from "../assets/icone/x.png";

/**
 * Script start
 */
function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    for (
      let i = 0;
      i < document.getElementsByClassName("card-header").length;
      i++
    ) {
      if (parseInt(i) !== parseInt(eventKey)) {
        document
          .getElementsByClassName("card-header")
          [i]?.classList.remove("open");
        document
          .getElementsByClassName("card-body")
          [i]?.classList.remove("open");
      }
    }

    document
      .getElementsByClassName("card-header")
      [eventKey]?.classList?.toggle("open");
    document
      .getElementsByClassName("card-body")
      [eventKey]?.classList?.toggle("open");
  });

  return (
    <button
      type="button"
      className="  text-left btn btn-link"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const Info = (props) => {
  const { t } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("pages.info.meta.title")}</title>
        <meta name="description" content={t("pages.info.meta.description")} />
      </Helmet>
      <Container fluid className="info-page">
        <Menu></Menu>
        <Row>
          <Col md={12} className="header">
            <img src={header} alt="Informazioni utili su Corenno Plinio" />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="text-center content-center">
            <p className="text-italic italic-title">
              {t("pages.info.firstRow.pretitle")}
            </p>
            <h1 className="text-bold">{t("pages.info.firstRow.title")}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: t("pages.info.firstRow.text"),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="text-left content-center">
            <Accordion>
              <Card>
                <Card.Header className="text-bold">
                  <CustomToggle eventKey={1}>
                    {t("pages.info.faq.1.title")}
                    <span>
                      <img className="logo-info_1" alt="Apri" src={logo_1} />
                      <img className="logo-info_2" alt="Chiudi" src={logo_2} />
                    </span>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body className="text-regular">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("pages.info.faq.1.text"),
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header className="text-bold">
                  <CustomToggle eventKey={2}>
                    {t("pages.info.faq.2.title")}
                    <span>
                      <img className="logo-info_1" alt="Apri" src={logo_1} />
                      <img className="logo-info_2" alt="Chiudi" src={logo_2} />
                    </span>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={2}>
                  <Card.Body className="text-regular">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("pages.info.faq.2.text"),
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header className="text-bold">
                  <CustomToggle eventKey={3}>
                    {t("pages.info.faq.3.title")}
                    <span>
                      <img className="logo-info_1" alt="Apri" src={logo_1} />
                      <img className="logo-info_2" alt="Chiudi" src={logo_2} />
                    </span>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={3}>
                  <Card.Body className="text-regular">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("pages.info.faq.3.text"),
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header className="text-bold">
                  <CustomToggle eventKey={4}>
                    {t("pages.info.faq.4.title")}
                    <span>
                      <img className="logo-info_1" alt="Apri" src={logo_1} />
                      <img className="logo-info_2" alt="Chiudi" src={logo_2} />
                    </span>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={4}>
                  <Card.Body className="text-regular">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("pages.info.faq.4.text"),
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
        <Footer />
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Info);
