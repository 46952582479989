// Core packages
import React, { useEffect, useState } from "react";

// Third party packages
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// Custom packages
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import SliderDot from "../components/SliderDot";
import iconinfo from "../assets/icone/icon-info-outline.png";
import iconcontatti from "../assets/icone/icon-contatti.png";
import labelp from "../assets/percorsi/label-pescatori.png";
import labelconte from "../assets/percorsi/label-conte.png";
import ar from "../assets/loghi/ar-sidebar.jpg";
import labelcavaliere from "../assets/percorsi/label-cavalieri.png";

/**
 * Script start
 */
const Luogo = (props) => {
  const { luoghi, curr_lang, t } = props;
  const [luogo, setLuogo] = useState({});

  const loadLuogo = () => {
    const id = window.location.pathname.split("/luogo/")[1];
    const currentLuogo = luoghi.find((el) => el.urw === id);
    setLuogo(currentLuogo);
  };

  useEffect(() => {
    loadLuogo();
  });

  if (!luogo) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${luogo?.tappa} ${t("pages.luogo.meta.title")}`}</title>
        <meta name="description" content={t("pages.luogo.meta.description")} />
      </Helmet>
      <Container fluid>
        <Menu></Menu>
        <Row>
          <Col md={8} className="text-left no-padding">
            <img
              alt={luogo?.tappa}
              src={`${process.env.PUBLIC_URL}/luoghi/luogo/molo.jpg`}
              style={{ maxWidth: "100%" }}
            />
            <Container>
              <Col md={10} className="content-center">
                <p className="breadcrumbs">
                  <a href={"/" + curr_lang + "/luoghi"}>
                    {t("pages.luogo.places")}
                  </a>{" "}
                  &gt; {luogo?.tappa}
                </p>
                <h1 className="text-bold title">{luogo?.tappa}</h1>
                <p> {luogo?.descrizione}</p>
              </Col>
            </Container>
          </Col>
          <Col md={4} className="gray-background text-left luogo-sidebar">
            {!luogo?.percorso?.some((value) => value.nome === "fuori") && (
              <div>
                <p className="text-bold label">
                  {t("pages.luogo.widgets.1.title")}
                </p>
                <p className="label-img">
                  {luogo?.percorso?.map((element) => {
                    switch (element.nome) {
                      case "pescatore":
                        return (
                          <img
                            alt={`Percorso ${element?.nome}`}
                            key={element.nome + element.pos}
                            src={labelp}
                          />
                        );

                      case "conte":
                        return (
                          <img
                            alt={`Percorso ${element?.nome}`}
                            key={element.nome + element.pos}
                            src={labelconte}
                          />
                        );

                      case "cavaliere":
                        return (
                          <img
                            alt={`Percorso ${element?.nome}`}
                            key={element.nome + element.pos}
                            src={labelcavaliere}
                          />
                        );

                      default:
                        return (
                          <React.Fragment key={element.nome + element.pos} />
                        );
                    }
                  })}
                </p>
              </div>
            )}

            <p className="text-bold label">
              {t("pages.luogo.widgets.2.title")}
            </p>
            <p className="label-img">
              <img
                alt={`Mappa ${luogo.tappa}`}
                src={`${process.env.PUBLIC_URL}/assets/img/luoghi/${luogo?.urw}/mappa/${luogo?.mappa}`}
              />
            </p>
            <p className="text-bold label">
              {t("pages.luogo.widgets.3.title")}
            </p>
            <p>
              <img alt="Info utili" src={iconinfo} className="info-icon" />
              <a className="text-link" href={"/" + curr_lang + "/info"}>
                {t("pages.luogo.widgets.3.info")}
              </a>
            </p>
            <p>
              <img alt="Contatti" src={iconcontatti} className="info-icon" />
              <a className="text-link" href={"/" + curr_lang + "/contatti"}>
                {t("pages.luogo.widgets.3.contacts")}
              </a>
            </p>
            <p className="label-img" style={{ marginTop: "70px" }}>
              <img alt="Realtà aumentata Corenno Plino" src={ar} />
            </p>
          </Col>
        </Row>
        <Row className="slider-row">
          <Col md={12} className="no-padding">
            <SliderDot />
          </Col>
        </Row>
        <Footer />
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Luogo);
