// Core packages
import React, { useEffect, useState } from "react";

// Third party packages
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";

// Custom packages
import Footer from "../Footer";
import Foto from "./media/Foto";
import Video from "./media/Video";
import close from "../../assets/icone/icon-close-circle.png";
import catIt from "../../data/it/categorie.json";
import catEn from "../../data/en/categorie.json";

/**
 * Script start
 */
const Content = (props) => {
  const { closeFn, luogo, curr_lang, t } = props;
  const [more, setMore] = useState(false);
  const [cat, setCat] = useState(curr_lang === "en" ? catEn : catIt);

  useEffect(() => {
    if (curr_lang === "en") {
      setCat(catEn);
    } else {
      setCat(catIt);
    }
  }, [curr_lang]);

  // const closeHandle = () => {
  //   document.getElementById("popup-info").style.display = "none";
  // };

  const toDegreesMinutesAndSeconds = (coordinate) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    return degrees + " ° " + minutes + "' " + seconds + '"';
  };

  const convertDMS = (lat, lng) => {
    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = lat >= 0 ? "N" : "S";
    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = lng >= 0 ? "E" : "W";
    return (
      <p className="descr text-regular">
        <strong>{latitudeCardinal}</strong> {latitude} &nbsp;{" "}
        <strong>{longitudeCardinal} </strong>
        {longitude}
      </p>
    );
  };

  return (
    <div className="ar-content">
      <div className="header-content">
        <img
          className="close-color"
          src={close}
          onClick={() => closeFn()}
          alt="Chiudi"
        />
        <img
          className="header-img"
          src={
            process.env.PUBLIC_URL +
            "/assets/img/luoghi/" +
            luogo.urw +
            "/cover/" +
            luogo.img
          }
          alt={luogo.tappa}
        />
      </div>

      <div className="ar-content-info">
        <h1 className="text-bold">{luogo.tappa}</h1>
        {cat.map((el) => {
          return luogo?.percorso?.forEach((el2) => {
            if (el.slug === el2.nome)
              <p className="category-cont" style={{ color: el.color }}>
                <img
                  className="icon-info"
                  alt={luogo.tappa}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/categorie/" +
                    el.path +
                    "icona/" +
                    el.img
                  }
                />
                <span className="info-mappa-cat" style={{ color: el.color }}>
                  {el.nome}
                </span>
              </p>;
          });
        })}

        <p className="text-regular descr">
          {more ? (
            <span>
              {luogo.descrizione.split("\n").map((item, key) => (
                <span key={key}>
                  {item}
                  <br />
                </span>
              ))}
              <p className="read-more " onClick={() => setMore(false)}>
                Chiudi
              </p>
            </span>
          ) : (
            <span>
              {luogo.breve &&
                luogo.breve.split("\n").map((item, key) => (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                ))}
              <p className="read-more " onClick={() => setMore(true)}>
                {t("general.readMore")}
              </p>
            </span>
          )}
        </p>
        <Tabs id="uncontrolled-tab-example">
          {luogo.gallery && (
            <Tab eventKey="foto" title="FOTO">
              <Foto gall={luogo.gallery} path={luogo.urw}></Foto>
            </Tab>
          )}
          {luogo?.video?.length > 0 && (
            <Tab eventKey="video" title="VIDEO">
              <Video video={luogo.video}></Video>
            </Tab>
          )}
        </Tabs>
      </div>
      <hr></hr>
      <div className="ar-content-info mappa-content">
        <p className="size-18 text-bold">MAPPA</p>
        <p className="label-img">
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/img/luoghi/" +
              luogo.urw +
              "/mappa/" +
              luogo.mappa
            }
            alt="Mappa"
          />
        </p>
        <br></br>
        <p className="descr text-regular">{luogo.indirizzo}</p>
        {luogo.pos && convertDMS(luogo.pos.lat, luogo.pos.lng)}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Content);
