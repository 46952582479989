// Core packages
import React from "react";

// Third party packages
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

// Custom packages
import Homepage from "./pages/Homepage";
import Luoghi from "./pages/Luoghi";
import Percorsi from "./pages/Percorsi";
import ArLanding from "./pages/ArLanding";
import Info from "./pages/Info";
import Contatti from "./pages/Contatti";
import Luogo from "./pages/Luogo";
import Borgo from "./pages/Borgo";
import Costruzione from "./pages/Costruzione";
import Privacy from "./pages/Privacy";
import NoteLegali from "./pages/NoteLegali";

// import Arapp from "./components/Ar-app";
import Filtro from "./components/Filtro";
import "./App.css";
import ArApp from "./pages/ArApp";

/**
 * Script start
 */
const App = () => {
  const supportedLanguages = ["en", "it"];
  const comingSoon = false;

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {comingSoon ? (
            <React.Fragment>
              <Route exact path="/" element={<Costruzione />} />
              <Route exact path="/:lang/" element={<Costruzione />} />
              <Route path="/:lang/ar-app/:placeId" element={<ArApp />} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Route path="/" element={<Homepage />} />
              <Route path="/:lang/" element={<Homepage />} />
              <Route path="/:lang/borgo" element={<Borgo />} />
              <Route path="/:lang/luoghi" element={<Luoghi />} />
              <Route path="/:lang/luogo/:id" element={<Luogo />} />
              <Route path="/:lang/luoghi/:id" element={<Luoghi />} />
              <Route path="/:lang/info" element={<Info />} />
              <Route path="/:lang/percorsi-tematici" element={<Percorsi />} />
              <Route path="/:lang/contatti" element={<Contatti />} />
              <Route path="/:lang/ar" element={<ArLanding />} />
              <Route path="/:lang/ar-app/:placeId" element={<ArApp />} />
              <Route path="/:lang/ar-app" element={<ArApp />} />
              {/* <Route path="/:lang/ar-app" element={<Arapp />} /> */}
              <Route path="/:lang/filtro" element={<Filtro />} />
              <Route path="/:lang/privacy-policy" element={<Privacy />} />
              <Route path="/:lang/note-legali" element={<NoteLegali />} />
              <Route
                path="/:lang/"
                render={(props) =>
                  supportedLanguages.includes(props.match.params.lang) ? (
                    <Homepage {...props} />
                  ) : (
                    <Navigate to={`/it/${props.match.params.lang}`} />
                  )
                }
              />
            </React.Fragment>
          )}
        </Routes>
      </BrowserRouter>
      <CookieConsent location="bottom" buttonText="Acconsento">
        Questo sito web utilizza cookies tecnici e di terze parti per tracciare
        le visite e per consentire un corretto funzionamento.
      </CookieConsent>
    </div>
  );
};

export default App;
